import React from 'react';
import { Container, Box, Grid, FormControl, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import styled from '@mui/system/styled';
import { Link } from '@last-rev/component-library';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const url = 'https://facebook.us8.list-manage.com/subscribe/post?u=cc8266774694f349525a4d1bc&amp;id=7397409878';

export interface MailchimpFormProps {}

interface FormFields {
  FNAME: string;
  EMAIL: string;
  PHONE?: string;
}

type StatusTypes = 'sending' | 'error' | 'success' | undefined | null;

type SubscribeType = (data: FormFields) => void;

interface CustomFormProps {
  status?: StatusTypes;
  message?: String | Error | null;
  subscribe: SubscribeType;
}
interface SubscribeFormData {
  FNAME: string;
  EMAIL: string;
  PHONE?: string;
}

const CustomForm = ({ status, message: rawMessage, subscribe }: CustomFormProps) => {
  const { handleSubmit, control } = useForm<SubscribeFormData>();
  const message = typeof rawMessage === 'string' ? rawMessage.split('. ')[0] : '';
  const loading = status === 'sending';
  return (
    <form
      id={`mc-embedded-subscribe-form`}
      onSubmit={handleSubmit(subscribe)}
      style={{ display: 'flex', justifyContent: 'center' }}>
      <FormContainer container maxWidth="md" spacing={5} sx={{ justifyContent: 'center' }}>
        <Grid sx={{ opacity: status === 'success' ? 0 : 1 }} container item xs={12} spacing={3}>
          {/* {status === 'error' ? <Box>Error {message}</Box> : null} */}
          <Grid item xs={12} sm={4}>
            <Controller
              name="FNAME"
              control={control}
              defaultValue=""
              rules={{ required: 'Name required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="mce-FNAME"
                  name="FNAME"
                  variant="filled"
                  fullWidth
                  margin="normal"
                  disabled={loading}
                  label="NAME*"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              )}
            />{' '}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="EMAIL"
              control={control}
              defaultValue=""
              rules={{ required: 'Email required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="mce-EMAIL"
                  name="EMAIL"
                  type="email"
                  fullWidth
                  margin="normal"
                  disabled={loading}
                  variant="filled"
                  label="EMAIL ADDRESS*"
                  value={value}
                  onChange={onChange}
                  error={!!error || !!message}
                  helperText={error ? error.message : message} // Use the MailChimp error message by default
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="PHONE"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="mce-PHONE"
                  name="PHONE"
                  fullWidth
                  margin="normal"
                  disabled={loading}
                  variant="filled"
                  label="PHONE"
                  value={value}
                  onChange={onChange}
                  error={!!error || !!message}
                  helperText={error ? error.message : message} // Use the MailChimp error message by default
                />
              )}
            />
          </Grid>
        </Grid>
        <SubmitContainer container item xs={12} sx={{ justifyContent: 'center' }}>
          <Grid item>
            <FormControl>
              {/* @ts-ignore */}
              <Link type="submit" disabled={loading} text="Subscribe" variant="button-contained" color="secondary" />
            </FormControl>
          </Grid>
        </SubmitContainer>
        <Grid
          container
          sx={{
            p: 5,
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            opacity: status === 'success' ? 1 : 0,
            pointerEvents: status === 'success' ? 'initial' : 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Box>
            <Typography variant="h3">Thanks for your subscribing!</Typography>
          </Box>
        </Grid>
      </FormContainer>
    </form>
  );
};

export const MailchimpForm = ({}: MailchimpFormProps) => (
  <Root data-testid="MailchimpForm">
    <ContentContainer>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm status={status} message={message} subscribe={subscribe} />
        )}
      />
    </ContentContainer>
  </Root>
);

const Root = styled(Box, {
  name: 'MailchimpForm',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => [styles.root]
})<{ variant?: string }>``;

const ContentContainer = styled(Container, {
  name: 'Form',
  slot: 'ContentContainer',
  overridesResolver: (_, styles) => [styles.contentContainer]
})<{ variant?: string }>``;

const FormContainer = styled(Grid, {
  name: 'Form',
  slot: 'FormContainer',
  overridesResolver: (_, styles) => [styles.formContainer]
})<{ variant?: string }>``;

const SubmitContainer = styled(Grid, {
  name: 'Form',
  slot: 'SubmitContainer',
  overridesResolver: (_, styles) => [styles.submitContainer]
})<{ variant?: string }>``;

export default MailchimpForm;
