import dynamic from 'next/dynamic';
import MailchimpForm from './components/MailchimpForm';
import CollectionImageGrid from './components/CollectionImageGrid';

const Footer = dynamic(() => import('./components/Footer'));

const Section = dynamic(() => import('./components/Section'));

const Card = dynamic(() => import('./components/Card'));

const Collection = dynamic(() => import('./components/Collection'));
const CollectionTimeline = dynamic(() => import('./components/CollectionTimeline'));

const CollectionAccordion = dynamic(() => import('./components/CollectionAccordion'));
const CollectionFiltered = dynamic(() => import('./components/CollectionFiltered'));

const NavigationBar = dynamic(() => import('./components/NavigationBar'));

const Media = dynamic(() => import('./components/Media'));

const Link = dynamic(() => import('./components/Link'));

const NavigationItem = dynamic(() => import('./components/NavigationItem'));

const Header = dynamic(() => import('./components/Header'));

const Hero = dynamic(() => import('./components/Hero'));

const Text = dynamic(() => import('./components/Text'));

// Custom components
const Page = dynamic(() => import('./components/Page'));
const Blog = dynamic(() => import('./components/PageBlog'));
const JuicerIntegration = dynamic(() => import('./components/JuicerIntegration'));

const contentMapping: {
  [key: string]: any;
} = {
  Header,
  Section,
  Collection,
  'Collection:accordion': CollectionAccordion,
  'Collection:image-grid': CollectionImageGrid,
  'Collection:filtered': CollectionFiltered,
  'Collection:timeline': CollectionTimeline,
  Card,
  Text,
  Media,
  Link,
  'Collection:navigation-bar': NavigationBar,
  NavigationItem,
  Hero,
  Page,
  Blog,
  Footer,
  'ModuleIntegration:MailchimpForm': MailchimpForm,
  'ModuleIntegration:JuicerIntegration': JuicerIntegration
};

export default contentMapping;
