import React from 'react';
import chunk from 'lodash/chunk';
import { Box, ImageList, ImageListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorBoundary from '@last-rev/component-library/dist/components/ErrorBoundary';
import { CardProps } from '@last-rev/component-library/dist/components/Card';
import ContentModule from '@last-rev/component-library/dist/components/ContentModule';
import { CollectionProps } from '@last-rev/component-library/dist/components/Collection';
import sidekick from '../../utils/sidekick';

export interface CollectionImageGridProps extends Omit<CollectionProps, 'items'> {
  items: CardProps[];
}

const getCols = (_total: number): number => {
  // if (total <= 1) return 2;
  return 1;
};

const getRows = (_index: number, _total: number): number => {
  // if (total <= 2) return 1;
  // if (index === 0) return 2;
  return 1;
};

export const CollectionImageGrid = ({ id, items, sidekickLookup }: CollectionImageGridProps) => {
  const itemData = chunk(items, 3) as CardProps[][];
  return (
    <ErrorBoundary>
      <Root {...sidekick(sidekickLookup)}>
        <ImageList variant="masonry" cols={3} gap={8} sx={{ display: ['none', 'block'] }}>
          {itemData.map((item, i) => (
            <React.Fragment key={`collection-grid-${id}-${i}`}>
              {item?.map((card: CardProps, index: number) => (
                <ImageListItem key={card.id} cols={getCols(item.length)} rows={getRows(index, item.length)}>
                  <ContentModule {...card} variant="media-grid" />
                </ImageListItem>
              ))}
            </React.Fragment>
          ))}
        </ImageList>
        <ImageList variant="masonry" cols={2} gap={8} sx={{ display: ['block', 'none'] }}>
          {itemData.map((item, i) => (
            <React.Fragment key={`collection-grid-${id}-${i}`}>
              {item?.map((card: CardProps, index: number) => (
                <ImageListItem key={card.id} cols={getCols(item.length)} rows={getRows(index, item.length)}>
                  <ContentModule {...card} variant="media-grid" />
                </ImageListItem>
              ))}
            </React.Fragment>
          ))}
        </ImageList>
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(Box, {
  name: 'CollectionImageGrid',
  slot: 'Root'
})<{ variant?: string }>(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    '& [class*="MuiPaper-root"]': {
      padding: '0'
    }
  },
  '& [class*="MuiImageList-root"]': {
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)!important'
    }
  }
}));

export default CollectionImageGrid;
